import * as React from 'react';
import Layout from '../components/layout';
import Tabs from '../components/tabs';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Accordion from '../components/accordion';
import ModalDialog from '../components/modaldialog';
import Video from '@bgea/kaltura-player/dist/KalturaPlayer';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Summit de Evangelismo"
      description="Como consecuencia de la interrupción que la pandemia causó en la adoración corporativa, la comunión fraternal y en la evangelización, nuestro deseo es alentar y equipar a los pastores y creyentes en su llamado a hacer discípulos de todas las naciones."
    />
    <section className="hero">
      <div className="hero-body scripture-container">
        <StaticImage
          src="../images/eager-to-preach-scripture-white.png"
          alt="Eager to preach"
          className="scripture"
        />
      </div>
    </section>
    <section className="main">
      <Tabs>
        <div label="Español">
          <div class="content" id="spanish-content">
            <h1>
              <span class="underline">¿Por qué el Summit de Evangelismo?</span>
            </h1>
            <p>
              Cada día, personas de todo el mundo, y en nuestras propias
              comunidades, buscan esperanza y significado. Como cristianos,
              podemos compartir con ellos la verdad de que «la dádiva de Dios es
              vida eterna en Cristo Jesús, nuestro Señor» (Romanos 6:23, NVI).
            </p>
            <p>
              Los líderes cristianos de todo el mundo anhelan el poder
              transformador de Dios para traer renovación a sus comunidades.
              Este poder no se encuentra en nuestras habilidades, dones,
              talentos ni en los programas que podamos desarrollar, ¡está en el
              Evangelio mismo!
            </p>
            <p>
              «A la verdad, no me avergüenzo del evangelio, pues es poder de
              Dios para la salvación de todos los que creen». —Romanos 1:16
            </p>
            <div id="getInvolvedVideo" className="videoContainer is-hidden">
              <section className="video">
                <div className="flexVideo">
                  <Video id="1_dkftibue" />
                </div>
              </section>
            </div>
            <hr />
            <h3>
              <span class="underline">Ubicaciones y registro</span>
            </h3>
            <p>
              Selecciona el mapa de tu ubicación preferida para registrarte y
              obtener más detalles.
            </p>
            <h4>2024</h4>
            <div className="columns locations">
              <div className="column is-4">
                <div class="card text-center">
                  <div class="card-content">
                    <a
                      class="content"
                      href="https://SdE2024Miami.eventbrite.com"
                      target="_blank"
                    >
                      <StaticImage
                        src="../images/florida-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>Miami, FL</h5>
                      <p>28 de septiembre</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div class="card text-center">
                  <div class="card-content">
                    <a
                      class="content"
                      href="https://SdE2024NY.eventbrite.com"
                      target="_blank"
                    >
                      <StaticImage
                        src="../images/new-york-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>New York, NY</h5>
                      <p>26 de octubre</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <h3>
              <span class="underline">Conferencistas</span>
            </h3>
            <section class="speakers">
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/WendyBello.jpg"
                    alt="Wendy Bello"
                    className="bio-photo"
                  />
                }
                buttonText="WENDY BELLO"
                content="<p><strong>WENDY BELLO</strong> es licenciada en Lengua y Literatura Inglesa; conferencista y autora de Un corazón nuevo, Una mujer sabia y Decisiones que transforman. Colabora en Coalición por el Evangelio y en el ministerio de mujeres de su iglesia local. Está casada con Abel y tienen dos hijos adolescentes. Actualmente cursa una Maestría en estudios teológicos en Southern Baptist Seminary.</p>"
                modalId="wendybello"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/brunson-andrew.jpg"
                    alt="Andrew Brunson"
                    className="bio-photo"
                  />
                }
                buttonText="ANDREW BRUNSON"
                content="<p><strong>ANDREW BRUNSON</strong> es un pastor estadounidense que participó en la fundación de múltiples iglesias, capacitaciones, ayuda a refugiados y la fundación de una casa de oración en Turquía durante 23 años junto a su esposa Norine. En octubre del año 2016 fue acusado de terrorismo y durante dos años fue prisionero en prisiones turcas. Debido a un movimiento de oración en todo el mundo y a la importante presión política del gobierno de los EE. UU., finalmente fue sentenciado y milagrosamente puesto en libertad en octubre del 2018. Andrew y Norine fundaron un ministerio llamado <em>WaveStarters</em>. Andrew tiene un doctorado de la Universidad de Aberdeen, Escocia. Andrew y Norine tienen tres hijos adultos.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/AndreaFranz.png"
                    alt="Andrea Franz"
                    className="bio-photo"
                  />
                }
                buttonText="ANDREA FRANZ"
                content="<p><strong>ANDREA FRANZ</strong> es líder juvenil. Junto a su esposo Sebastián lideran el ministerio Volviendo a la Esencia en Oklahoma, Estados Unidos. Volviendo a la Esencia es un llamado que impulsa a esta generación a vivir una vida dependiente, consagrada y devota al Espíritu Santo, donde Jesús es el centro de nuestras vidas.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/Sebasti%C3%A1nFranz.jpg"
                    alt="Sebastián Franz"
                    className="bio-photo"
                  />
                }
                buttonText="SEBASTIÁN FRANZ"
                content="<p><strong>SEBASTIÁN FRANZ</strong> es pastor y líder juvenil. Junto a su esposa Andrea lideran el ministerio Volviendo a la Esencia en Oklahoma, Estados Unidos. Volviendo a la Esencia es un llamado que impulsa a esta generación a vivir una vida dependiente, consagrada y devota al Espíritu Santo, donde Jesús es el centro de nuestras vidas.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/JorgeGil.jpg"
                    alt="Jorge Gil"
                    className="bio-photo"
                  />
                }
                buttonText="JORGE GIL"
                content="<p><strong>JORGE GIL</strong> es un talentoso comunicador enfocado en encontrar nuevas formas de llevar la verdad a las nuevas generaciones. Originario de Costa Rica, Jorge es un autor publicado y cofundador de la Sociedad de Apologistas Latinos. Viaja con frecuencia a América Latina y el Caribe para participar en conferencias, podcasts, programas de radio y televisión, y para capacitar y equipar a líderes de todo el mundo. Su amor por la tecnología lo convierte en una persona valiosa en CrossExamined.org, donde dirige la producción de sus podcasts, transmisiones en vivo y más.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/graham-edward.jpg"
                    alt="Edward Graham"
                    className="bio-photo"
                  />
                }
                buttonText="EDWARD GRAHAM"
                content="<p><strong>EDWARD GRAHAM</strong> es el hijo menor de Franklin y Jane Graham, y nieto de Billy Graham. Se desempeña como director de operaciones de Samaritan's Purse. Edward se graduó de la academia militar de los Estados Unidos, donde sirvió 16 años en el ejército de los Estados Unidos. Después de múltiples despliegues de combate dentro del equipo de operaciones especiales y sirviendo en varios puestos de liderazgo, se sintió llamado por el Señor a regresar a casa y servir en el ministerio a partir del invierno de 2018. Edward y Kristy han estado casados por más de 18 años y tienen una hija y tres hijos. Están criando a sus cuatro hijos en las montañas de Carolina del Norte.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/graham-will-2.jpg"
                    alt="Will Graham"
                    className="bio-photo"
                  />
                }
                buttonText="WILL GRAHAM"
                content="<p><strong>WILL GRAHAM</strong> es la tercera generación de Graham en proclamar las Buenas Nuevas de Jesucristo bajo el estandarte de la BGEA, ha compartido el Evangelio con más de un millón de personas en seis continentes desde que comenzó su ministerio evangelístico en el 2006. Will también se desempeña como Vicepresidente Ejecutivo de la Billy Graham Evangelistic Association, que brinda liderazgo a todos los aspectos de la organización que su abuelo Billy Graham fundó en 1950. Will y su esposa, Kendra, tienen dos hijas, Christine Jane (CJ) y Rachel Austin, y un hijo, William Franklin Graham V (Quinn) y vive en las montañas de Carolina del Norte.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/graham-lynch-cissie.jpg"
                    alt="CISSIE GRAHAM LYNCH"
                    className="bio-photo"
                  />
                }
                buttonText="CISSIE GRAHAM LYNCH"
                content="<p><strong>CISSIE GRAHAM LYNCH</strong> es la menor de los hijos de Franklin y Jane Graham. Le apasiona abogar por los necesitados y compartir la esperanza de Jesús con los que están pasando por dificultades. Trabaja junto a su padre y sus hermanos en la Billy Graham Evangelistic Association y en Samaritan's Purse y tiene su propio podcast llamado <em>Fearless</em>. Actualmente vive en Florida con su esposo Corey y sus tres hijos.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/AngelJordan.png"
                    alt="ANGEL JORDAN"
                    className="bio-photo"
                  />
                }
                buttonText="ANGEL JORDAN"
                content="<p><strong>ANGEL JORDAN</strong> es el Director de Iniciativas Hispanas de la Billy Graham Evangelistic Association y de Samaritan’s Purse. Él tiene una licenciatura en mercadotecnia y publicidad, una maestría en publicidad digital, una maestría en estudios cristianos, un certificado en inteligencia cultural y en perspectivas globales. Ha trabajado como consultor de marketing para grandes compañías como: <em>Affirm Films</em>, CanZion, <em>Columbia Pictures</em>, entre otras. Tiene gran pasión por la transformación social, compartir esperanza y ayudar a personas a comunicarse mejor entre culturas. Guatemalteco por nacimiento; mexicano de corazón, donde vivió más de una década, y americano por amor. Angel y su esposa Anne tienen dos hijos: Mateo y Lucas, actualmente viven en Boone, NC.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/JoeMott.png"
                    alt="JOE MOTT"
                    className="bio-photo"
                  />
                }
                buttonText="JOE MOTT"
                content="<p>Con experiencia como reportero de noticias de televisión, Joe Mott comenzó a servir a Dios con la Billy Graham Evangelistic Association (BGEA) en septiembre del 2006. Joe sirvió en el proyecto Mi Esperanza de BGEA durante nueve años en seis países diferentes de América Latina y África, movilizando a cristianos de todo el país para compartir su fe con sus familiares, amigos y vecinos, a través de una transmisión televisiva a nivel nacional.</p><p>Joe hizo la transición a Samaritan's Purse en 2016, donde sirvió en Operation Christmas Child como Jefe regional para América del Sur, Jefe de relaciones de voluntarios y Director de programas globales de OCC International. Junto a un equipo increíble, Joe pudo ver la participación de grupos étnicos no alcanzados en América Latina, África y Asia a través del ministerio de evangelismo y discipulado de Operation Christmas Child.</p><p>Joe actualmente dirige los Ministerios Hispanos de Samaritan's Purse y la Billy Graham Evangelistic Association que equipa, moviliza y colabora con la iglesia hispana para tener el mayor impacto evangelístico posible. A Joe le apasiona ver a la iglesia local en todo el mundo activa en la proclamación y demostración del Evangelio.</p><p>Joe está casado con Melissa y tienen dos hijos; Caleb y Lily.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/mottesi-alberto.jpg"
                    alt="DR. ALBERTO MOTTESI"
                    className="bio-photo"
                  />
                }
                buttonText="DR. ALBERTO MOTTESI"
                content="<p><strong>DR. ALBERTO MOTTESI</strong> es uno de los conferencistas más reconocidos y respetados del mundo hispanoamericano. Sus programas de radio y televisión son transmitidos diariamente por más de 10 000 canales de medios masivos. La NRB le otorgó el «Micrófono de Oro» por su excelencia en las comunicaciones. La prensa lo ha llamado el «Pastor de los presidentes» por su continuo ministerio a líderes de diferentes niveles en las naciones latinoamericanas. Sus seminarios de liderazgo atraen anualmente a miles de líderes de todos los orígenes, y sus reuniones masivas se encuentran entre algunos de los movimientos de masas más grandes en la historia del pueblo hispano. En los últimos 40 años, ha predicado en persona a más de 30 millones de personas. Ha publicado 17 libros. Su obra literaria «América 500 años después» ha sido adoptada como libro de texto en el sistema educativo de varios países. Continúa disertando en congresos nacionales e internacionales. Ha recibido grados doctorales de cuatro universidades en los Estados Unidos, Colombia y México.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/palau-kevin.jpg"
                    alt="KEVIN PALAU"
                    className="bio-photo"
                  />
                }
                buttonText="KEVIN PALAU"
                content="<p><strong>KEVIN PALAU</strong> es hijo del evangelista internacional Luis Palau. Se incorporó a la Asociación Luis Palau en 1985 y empezó a dirigir el día a día del ministerio a finales de los años noventa. Bajo su liderazgo, LPA ha producido algunos de los eventos cristianos más grandes jamás organizados, ha creado una red mundial de cientos de evangelistas asociados y ha desarrollado nuevos modelos para el alcance de toda la ciudad que integran importantes iniciativas de servicio comunitario con reuniones evangelísticas al aire libre. Kevin tiene un título en estudios religiosos de <em>Wheaton College</em>. Hoy Kevin se desempeña como C.E.O. de la Asociación Luis Palau, y vive en Beaverton Oregon con su esposa Michelle y sus tres hijos adultos jóvenes.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/porras-sixto.jpg"
                    alt="SIXTO PORRAS"
                    className="bio-photo"
                  />
                }
                buttonText="SIXTO PORRAS"
                content="<p><strong>SIXTO PORRAS</strong> es el vicepresidente de Desarrollo del ministerio hispano de Enfoque a la Familia, el ministerio de habla hispana de <em>Focus on the Family</em>. Ha producido más de 12 000 programas de radio y televisión, que han sido transmitidos en más de 684 estaciones y 38 países. Como un orador talentoso es frecuentemente entrevistado por múltiples medios de comunicación sobre temas de familia y ha sido orador ante las misiones diplomáticas en América Latina en la sede de la ONU en Nueva York. Ha hablado con miles de personas en temas relacionados con la familia, la juventud, la comunicación entre padres e hijos y el desarrollo personal en numerosos países de América Latina y el mundo. Sixto también es autor y ganador de premios. Actualmente vive en Colorado con su esposa Helen y tiene dos hijos y tres nietos.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/SaraiRivera.png"
                    alt="SARAI RIVERA"
                    className="bio-photo"
                  />
                }
                buttonText="SARAI RIVERA"
                content="<p><strong>SARAI RIVERA</strong>, una talentosa compositora y cantante, tiene sus raíces espirituales y musicales en una profunda conexión con su fe desde una edad temprana. Criada en un hogar pastoral, Sarai estuvo inmersa en la atmósfera de su iglesia local, donde la adoración se experimenta y practica como una parte natural de la vida diaria. Sin embargo, no fue hasta su adolescencia que descubrió su verdadero don para la música, talento que atribuye a las fervientes oraciones de su padre, quien suplicó a Dios por su nacimiento.</p><p>Con una voz poderosa y una pasión inquebrantable, Sarai se ha consolidado como una adoradora que expresa abiertamente su amor y devoción hacia Dios, quien le concedió la vida y el llamado a glorificarlo a través de su arte. Desde los 12 años comenzó a explorar la composición de canciones, un viaje que se intensificó a los 13, en la intimidad de su dormitorio, frente a un piano. Fueron esos momentos de adoración personal donde Sarai experimentó un encuentro transformador con Jesucristo, marcando el comienzo de una relación que continúa inspirando su música y su vida.</p><p>A través de sus canciones, Sarai Rivera busca no sólo compartir su fe sino también transmitir el mensaje de salvación, esperanza y amor, invitando a otros a experimentar la misma transformación y paz que ella ha conocido en Cristo.</p>"
              />
            </section>
            <hr />
            <h3>
              <span class="underline">Temas</span>
            </h3>
            <p>
              El Summit de Evangelismo ha sido diseñado para recordarle a la
              Iglesia las Buenas Nuevas de Jesucristo. A la luz de esa verdad,
              exploraremos nuestro llamado a:
            </p>
            <section class="topics">
              <Accordion
                title="PROCLAMAMOS LA CRUZ POR AMOR A SU NOMBRE"
                content="<p>Todas las personas involucradas en la evangelización saben que la palabra Evangelio significa «¡Buenas nuevas!». Cantamos sobre la cruz; nos regocijamos en la cruz; meditamos en la cruz. Cada vez que compartimos la cena del Señor, recordamos la cruz en obediencia al mandato de Jesús. Ha sido objeto de tantas pinturas a lo largo de la historia, e innumerables personas la usan como símbolo de su fe. Sin duda es una buena noticia. Pero cuando se trata de testificar con aquellos que aún no son creyentes, la cruz es a menudo la parte más difícil de proclamar del Evangelio.</p>
                <p>Como Iglesia, nuestra gloriosa tarea no es solo hablar sobre la hermosura y la humildad de Jesús, por maravillosas que sean esas características, ni sobre la buena voluntad y la bondad de la Iglesia, las cuales Jesús dijo que deberían ser evidentes para todos. «Predicamos a Cristo crucificado» (1 Corintios 1:23) porque sin Él, no hay Evangelio ni salvación. La cruz es el corazón del Evangelio.</p>"
              />
              <Accordion
                title="VIVIMOS VIDAS SANTAS POR AMOR A SU NOMBRE"
                content="<p>Es triste que nuestra cultura vea al liderazgo de la Iglesia con recelo, con lástima o simplemente le consideren como un divertimiento o como a personas débiles. En realidad, nada podría estar más lejos de la verdad. Somos llamados a vivir vidas santas. Pedro nos suplica: <em>«Más bien, sean ustedes santos en todo lo que hagan, como también es santo quien los llamó; pues está escrito: “Sean santos, porque yo soy santo”»</em> (1 Pedro 1:15-16).</p>
                <p>Lamentablemente, hoy en día, con demasiada frecuencia escuchamos de líderes cristianos que fracasan, provocando que las personas se alejen de Cristo y anulando su testimonio del Evangelio. Debemos ser aquellos que siguen la enseñanza de Pablo de que <em>«todo lo que hagan, de palabra o de obra, háganlo en el nombre del Señor Jesús»</em> (Colosenses 3:17)</p>"
              />
              <Accordion
                title="SOPORTAMOS EL COSTO DEL EVANGELIO POR AMOR A SU NOMBRE"
                content="<p>¡Todo el mundo sabe que las ovejas y los lobos no se llevan bien! Sin embargo, en Mateo 10:16, esta es la metáfora que Jesús usó para describir la relación entre los enviados a predicar el Evangelio del reino de Dios y el mundo al que fueron. Les enseñaba a sus discípulos que llevar el Evangelio al mundo a menudo será incomprendido, costoso, doloroso, divisivo, solitario y, en algunos casos, incluso ilegal y ¡potencialmente mortal!</p>
                <p>Los profetas del Antiguo Testamento sufrieron con frecuencia estas consecuencias, y ciertamente los discípulos del Nuevo Testamento también las experimentaron. La tradición indica que, de los 12 discípulos de Jesús, solo Juan murió de causas naturales; el resto fueron martirizados. Hay un gran costo al compartir el evangelio en estos tiempos. <em>«Por causa de mi nombre todo el mundo los odiará»</em>, dijo Jesús (Véase Mateo 10:22).</p>
                <p>El costo no siempre es la muerte, pero se requiere resistencia. Como portadores de las Buenas Nuevas, por lo general la pregunta no es: «¿Estás dispuesto a morir por amor a su nombre?» sino, «¿estás dispuesto a vivir por amor a su nombre?». ¿Estás dispuesto a vivir con resistencia, soportando el dolor de la obediencia porque somos enviados a un mundo hostil?</p>"
              />
              <Accordion
                title="NOS UNIMOS CON TODA LA IGLESIA POR AMOR A SU NOMBRE"
                content="<p>Si le preguntas a cualquier persona qué piensa de la Iglesia de Jesucristo, es probable que obtengas una de dos respuestas. Algunos dirán: «La iglesia es irrelevante, y casi no tiene relación con mi vida». Otros comentarán: «¿De qué iglesia estás hablando? Hay tantas, y se pasan el tiempo discutiendo entre ellas». Está claro que las diferencias internas, por bien intencionadas que sean, pueden convertirse fácilmente en una barrera para la evangelización del mundo. Pero ¿podemos los cristianos unirnos, especialmente cuando nuestras diferencias son a menudo por razones buenas y legítimas?</p>
                <p>¿Qué es lo que nos une? La obra de Jesucristo y la predicación de su mensaje al mundo es fundamental para unir a los creyentes. Su obra, no la nuestra, nos une. Por lo tanto, el llamado de Pedro es a <em>«amarse de todo corazón los unos a los otros»</em> (1 Pedro 1:22).</p>"
              />
              <Accordion
                title="DEFENDEMOS EL EVANGELIO POR AMOR A SU NOMBRE"
                content="<p>El Evangelio siempre ha estado bajo ataque. Por lo tanto, no debería sorprendernos si nosotros también somos rechazados y el mensaje es despreciado. Fue la promesa de Jesús cuando dijo: <em>«Si el mundo los aborrece, tengan presente que antes que a ustedes, me aborreció a mí… Si a mí me han perseguido, también a ustedes los perseguirán»</em> (Juan 15:18, 20).</p>
                <p>Esto significa que los ataques contra el Evangelio mismo, contra la Iglesia y contra nosotros personalmente, no son motivo para abatirnos ni desalentarnos. Nuestra responsabilidad no es solo predicar y vivir el Evangelio como testimonio al mundo, sino también defenderlo. Cuando surge la oposición, debemos aprovechar las oportunidades para defender el Evangelio.</p>
                <p>El apóstol Pablo vio esta defensa como una de las principales razones de su encarcelamiento. Él escribió a los filipenses: <em>«He sido puesto para la defensa del evangelio»</em> (Filipenses 1:16).</p>
                <p>Entonces, ¿cómo podemos prepararnos para desempeñar nuestro rol? ¿Cómo podemos responder a la amonestación de Judas de <em>«luchar vigorosamente por la fe encomendada de una vez por todas a los santos»</em>? (Judas 1:3).</p>"
              />
              <Accordion
                title="HACEMOS DISCIPULOS POR AMOR A SU NOMBRE"
                content="<p>Desde la perspectiva del Nuevo Testamento, no hay duda de que el discipulado es parte del evangelismo. De hecho, el corazón de la Gran Comisión que Jesús le dio a los apóstoles (véase Mateo 28:19-20) era ir y hacer discípulos de todas las naciones.</p>
                <p>Este no era un concepto nuevo. Isaías, por ejemplo, le encomendó su enseñanza a un grupo de discípulos (Isaías 8:16), al igual que lo hicieron las escuelas y prácticas rabínicas. Jesús también lo enfatizó a lo largo de su ministerio, sobre todo cuando instruyó a sus discípulos a alcanzar a otros: <em>«enséñenles a obedecer todo lo que les he mandado a ustedes»</em> (Mateo 28:20).</p>
                <p>Este doble énfasis de llamar a la gente era, en primer lugar, a la fe en Jesús y su obra salvadora, pero también a una vida de obediencia diaria a sus enseñanzas (véase Hechos 6:7). No debemos separarlas.</p>"
              />
            </section>
            <hr />
            <h3>
              <span class="underline">Horario</span>
            </h3>
            <ul class="schedule">
              <li>
                9 AM - 12 PM <span>Sesiones de la mañana</span>
              </li>
              <li>
                12 PM - 1 PM <span>Almuerzo</span>
              </li>
              <li>
                1 PM - 4 PM <span>Sesiones de la tarde</span>
              </li>
              <li>
                4 PM <span>Fin del evento</span>
              </li>
            </ul>
            <hr />
            <h3>
              <span class="underline">Recursos</span>
            </h3>
            <p>
              Usa los recursos descargables a continuación para promover el
              Summit de Evangelismo con los líderes de tu iglesia.
            </p>
            <Accordion
              title="Recursos"
              content="
        <div class='downloadWrapper'>
        <div class='downloadTitle'>
        <div class='downloadIcon'></div>
        <a href='https://vimeo.com/915335869/64ba251705?share=copy' style='text-transform: unset'><h4>Summit de evangelismo 2024 tease 3 horizontal</h4></a>
        </div>
        <a href='https://cdnapisec.kaltura.com/p/650742/sp/65074200/playManifest/entryId/1_va2jx7yl/format/download/protocol/https/flavorParamIds/0' target='_blank' class='button'>Descargar</a>
        </div>
        <div class='downloadWrapper'>
        <div class='downloadTitle'>
        <div class='downloadIcon'></div>
        <a href='https://vimeo.com/917180696/b215b2efbe?share=copy' style='text-transform: unset;'><h4>Summit de evangelismo 2024 tease 3 vertical</h4></a>
        </div>
        <a href='https://cdnapisec.kaltura.com/p/650742/sp/65074200/playManifest/entryId/1_w3rene1s/format/download/protocol/https/flavorParamIds/0' target='_blank' class='button'>Descargar</a>
        </div>
        <div class='downloadWrapper'>
        <div class='downloadTitle'>
        <div class='downloadIcon'></div>
        <h4>Summits de Evangelismo 2023 Recap</h4>
        </div>
        <a href='https://cdnapisec.kaltura.com/p/650742/sp/65074200/playManifest/entryId/1_9jw7tzks/format/download/protocol/https/flavorParamIds/0' target='_blank' class='button'>Descargar</a>
        </div>
        <div class='downloadWrapper'>
        <div class='downloadTitle'>
        <div class='downloadIcon'></div>
        <h4>Gráficos para redes sociales</h4>
        </div>
        <a href='/resources/promo-2024.zip' target='_blank' class='button'>Descargar</a>
        </div>
        <div class='downloadWrapper'>
        <div class='downloadTitle'>
        <div class='downloadIcon'></div>
        <h4>Poster descargable</h4>
        </div>
        <a href='https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/04/20171-Hispanic-Initiatives-Evangelism-Summit-2024-PROMO-Poster-PRESS-woBleed.pdf' target='_blank' class='button'>Descargar</a>
        </div>
        "
            />
            <hr />
            <h3>
              <span class="underline">Preguntas frecuentes</span>
            </h3>
            <Accordion
              title="¿Quién puede asistir?"
              content="Pastores y líderes de iglesias."
            />
            <Accordion
              title="¿Cuál es el costo?"
              content="El evento es gratis."
            />
            <Accordion
              title="¿A qué hora comienza?"
              content="El evento comienza a las 9 a.m. y termina a las 4 p.m."
            />
            <Accordion
              title="¿Debo traer o comprar mi almuerzo?"
              content="Vamos a proveer el almuerzo para todos los asistentes."
            />
            <Accordion
              title="¿El evento se va a transmitir en las redes sociales?"
              content="No, el evento es en persona solamente.<br/><br/>Si tienes más preguntas por favor envía un email a hispanic@bgea.org"
            />
            <hr />
            <h3>
              <span class="underline">Eventos pasados</span>
            </h3>
          </div>
          <div className="columns locations">
            <div className="column is-4">
              <div class="card text-center">
                <a
                  href="https://es.billygraham.org/noticias/summit-de-evangelismo-da-inicio-en-los-angeles/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="card-content">
                    <div class="content">
                      <StaticImage
                        src="../images/california-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>Los Angeles, CA</h5>
                      <p>6 de agosto, 2022</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="column is-4">
              <div class="card">
                <a
                  href="https://es.billygraham.org/galeria/la-comunidad-hispana-de-chicago-le-dio-bienvenida-a-summit-de-evangelismo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="card-content">
                    <div class="content">
                      <StaticImage
                        src="../images/illinois-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>Chicago, IL</h5>
                      <p>10 de septiembre, 2022</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="column is-4">
              <div class="card">
                <a
                  href="https://es.billygraham.org/noticias/la-presencia-de-dios-esta-aqui-summit-de-evangelismo-llama-a-la-iglesia-hispana-a-la-unidad/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="card-content">
                    <div class="content">
                      <StaticImage
                        src="../images/texas-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>Coppell, TX</h5>
                      <p>15 de octubre, 2022</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="column is-4">
              <div class="card text-center">
                <a
                  href="https://es.billygraham.org/noticias/pastores-del-valle-del-rio-grande-animados-a-volver-al-corazon-del-evangelio/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="card-content">
                    <div class="content">
                      <StaticImage
                        src="../images/texas-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>McAllen, TX</h5>
                      <p>30 de septiembre 2023</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="column is-4">
              <div class="card text-center">
                <a
                  href="https://es.billygraham.org/galeria/un-llamado-a-la-unidad-en-phoenix/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="card-content">
                    <div class="content">
                      <StaticImage
                        src="../images/arizona-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>Phoenix, AZ</h5>
                      <p>7 de octubre 2023</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="column is-4">
              <div class="card text-center">
                <a
                  href="https://es.billygraham.org/noticias/llamado-a-servir-en-el-ministerio-tras-la-jubilacion/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="card-content">
                    <div class="content">
                      <StaticImage
                        src="../images/texas-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>El Paso, TX</h5>
                      <p>14 de octubre 2023</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="column is-4">
              <div class="card text-center">
                <a
                  href="https://es.billygraham.org/galeria/nuevas-fuerzas-para-pastores-en-san-diego/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="card-content">
                    <div class="content">
                      <StaticImage
                        src="../images/california-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>San Diego, CA</h5>
                      <p>28 de octubre 2023</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="column is-4">
                <div class="card text-center">
                  <div class="card-content">
                    <a
                      class="content"
                      href="https://es.billygraham.org/galeria/pastores-y-lideres-hispanos-encuentran-renovacion-espiritual-en-denver/"
                      target="_blank"
                    >
                      <StaticImage
                        src="../images/colorado-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>Denver, CO</h5>
                      <p>3 de agosto 2024</p>
                    </a>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div label="English">
          <div className="content" id="english-content" lang="en">
            <h1>
              <span class="underline">Why the Evangelism Summit?</span>
            </h1>
            <p>
              Today, people around the world—and in our own communities—are
              searching for hope and meaning. As Christians, we can share with
              them the truth that “the free gift of God is eternal life in
              Christ Jesus our Lord” (Romans 6:23, ESV).
            </p>
            <p>
              Christian leaders around the world long for God’s transformative
              power to bring renewal to their communities. This power is not
              found in our skills, gifts, abilities, or the programs we may
              develop—it’s in the Gospel itself!
            </p>
            <p>
              “I am not ashamed of the gospel, for it is the power of God for
              salvation to everyone who believes.” —Romans 1:16
            </p>
            <hr />
            <h3>
              <span class="underline">Summit Locations & Registration</span>
            </h3>
            <p>
              Click on the map of your preferred location to register and to
              have more details.
            </p>
            <h4>2024</h4>
            <div className="columns locations">
              <div className="column is-4">
                <div class="card text-center">
                  <div class="card-content">
                    <a
                      class="content"
                      href="https://SdE2024Miami.eventbrite.com"
                      target="_blank"
                    >
                      <StaticImage
                        src="../images/florida-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>Miami, FL</h5>
                      <p>September 28</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className="column is-4">
                <div class="card text-center">
                  <div class="card-content">
                    <a
                      class="content"
                      href="https://SdE2024NY.eventbrite.com"
                      target="_blank"
                    >
                      <StaticImage
                        src="../images/new-york-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>New York, NY</h5>
                      <p>October 26</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <h3>
              <span class="underline">Speakers</span>
            </h3>
            <section class="speakers">
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/WendyBello.jpg"
                    alt="Wendy Bello"
                    className="bio-photo"
                  />
                }
                buttonText="WENDY BELLO"
                content="<p><strong>WENDY BELLO</strong> is a speaker and the author of <em> Un Corazón Nuevo [A New Heart], Una Mujer Sabia [A Wise Woman], and Decisiones que Transforman [Decisions that Transform] </em> . She serves at The Gospel Coalition and in the women’s ministry at her local church. She is married to Abel and they have two teenage children. Currently, she is pursuing a master’s degree in theological studies at the Southern Baptist Theological Seminary.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/brunson-andrew.jpg"
                    alt="Andrew Brunson"
                    className="bio-photo"
                  />
                }
                buttonText="ANDREW BRUNSON"
                content="<p><strong>ANDREW BRUNSON</strong> is an American pastor who was involved in starting churches, training, aid to refugees, and a house of prayer in Turkey for 23 years with his wife Norine. He was then accused of terrorism in October 2016 and was held for two years in Turkish prisons. Due to a worldwide prayer movement and significant political pressure from the US government, he was finally sentenced and then dramatically released in October 2018. Andrew and Norine have founded a ministry named <em>WaveStarters</em>. Andrew has a PhD from the University of Aberdeen, Scotland. Andrew and Norine have three grown children.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/AndreaFranz.png"
                    alt="Andrea Franz"
                    className="bio-photo"
                  />
                }
                buttonText="ANDREA FRANZ"
                content="<p><strong>ANDREA FRANZ</strong> is a youth leader. She and her husband Sebastian lead the ministry Volviendo a la Esencia in Oklahoma, United States. Volviendo a la Esencia is a call that encourages this generation to live a dependent, consecrated and devoted life to the Holy Spirit, where Jesus is the center of our lives.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/Sebasti%C3%A1nFranz.jpg"
                    alt="Sebastián Franz"
                    className="bio-photo"
                  />
                }
                buttonText="SEBASTIÁN FRANZ"
                content="<p><strong>SEBASTIÁN FRANZ</strong> is a pastor and youth leader. He and his wife Andrea lead the ministry Volviendo a la Esencia in Oklahoma, United States. Volviendo a la Esencia is a call that encourages this generation to live a dependent, consecrated and devoted life to the Holy Spirit, where Jesus is the center of our lives.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/JorgeGil.jpg"
                    alt="Jorge Gil"
                    className="bio-photo"
                  />
                }
                buttonText="JORGE GIL"
                content="<p><strong>JORGE GIL</strong> is a talented communicator focused on finding new ways to deliver the truth to new generations. Originally from Costa Rica, Jorge is a published author, and the co-founder of the Sociedad de Apologistas Latinos. He frequently travels to Latin America and the Caribbean to participate in conferences, podcasts, radio and TV shows, and to train and equip leaders across the globe. His love of technology makes him an incredible asset at CrossExamined.org where he leads the production for their podcasts, livestreams and more.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/graham-edward.jpg"
                    alt="Edward Graham"
                    className="bio-photo"
                  />
                }
                buttonText="EDWARD GRAHAM"
                content="<p><strong>EDWARD GRAHAM</strong> is the youngest son of Franklin and Jane Graham, and the grandson of Billy Graham. He serves as the Chief Operating Officer for Samaritan’s Purse.Edward graduated from the United States Military Academy where he went on to serve 16 years in the US Army. After multiple combat deployments within Special Operations and serving in various leadership positions, he felt called by the Lord to return home and serve in the ministry starting in the winter of 2018.Edward and Kristy have been married for more than 18 years, and have one daughter and three sons. They are raising their four children in the mountains of North Carolina.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/graham-will-2.jpg"
                    alt="Will Graham"
                    className="bio-photo"
                  />
                }
                buttonText="WILL GRAHAM"
                content="<p><strong>WILL GRAHAM</strong>, The third generation of Grahams to proclaim the Good News of Jesus Christ under the banner of the BGEA, Will has shared the Gospel with more than one million people across six continents since beginning his evangelistic ministry in 2006. Will also serves as Executive Vice President of the Billy Graham Evangelistic Association, giving leadership to all aspects of the organization that his grandfather Billy Graham founded in 1950. Will and his wife, Kendra, have two daughters, Christine Jane (CJ) and Rachel Austin, and a son, William Franklin Graham V (Quinn), and live in the mountains of North Carolina.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/graham-lynch-cissie.jpg"
                    alt="CISSIE GRAHAM LYNCH"
                    className="bio-photo"
                  />
                }
                buttonText="CISSIE GRAHAM LYNCH"
                content="<p><strong>CISSIE GRAHAM LYNCH</strong> is the youngest of Franklin and Jane Graham’s children. She has a passion to advocate for those in need and to share the hope of Jesus to those who are struggling. She works alongside her father and siblings at the Billy Graham Evangelistic Association and Samaritan’s Purse and has her own podcast called <em>Fearless</em>. She currently lives in Florida with her husband Corey and their three children.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/AngelJordan.png"
                    alt="ANGEL JORDAN"
                    className="bio-photo"
                  />
                }
                buttonText="ANGEL JORDAN"
                content="<p><strong>ANGEL JORDAN</strong> is the director of Hispanic Initiatives for the Billy Graham Evangelistic Association and Samaritan’s Purse. He has a bachelor’s degree in marketing and advertising, a master’s degree in digital advertising, a master’s degree in Christian studies, and is certified in cultural intelligence and global perspectives. He has worked as a marketing consultant for large companies such as Affirm Films, CanZion, Columbia Pictures, and others. He has a passion for social transformation, sharing hope, and helping people communicate better across cultures. He is Guatemalan by birth, Mexican by heart—where he lived for more than a decade—and American by love. Angel and his wife, Anne, have two sons, Mateo and Lucas, and currently live in Boone, North Carolina.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/JoeMott.png"
                    alt="JOE MOTT"
                    className="bio-photo"
                  />
                }
                buttonText="JOE MOTT"
                content="<p>From a background as a television news reporter, Joe Mott began serving God in ministry with the Billy Graham Evangelistic Association (BGEA) in September of 2006.  Joe served in the BGEA’s My Hope project for nine years in six different countries in Latin America and Africa, mobilizing Christians across a country to share their faith with their family, friends, and neighbors, through a nation-wide TV broadcast.</p><p>Joe transitioned up to Samaritan’s Purse in 2016 where he served in Operation Christmas Child as the Regional Manager for South America, the Manager for Volunteer Relations, and the Director for Global Programs for OCC International.  Alongside an amazing team, Joe got to see the engagement of Unreached People Groups in Latin America, Africa and Asia through the evangelism and discipleship ministry of Operation Christmas Child.</p><p>Joe currently leads Hispanic Ministries, a ministry of Samaritan’s Purse and the Billy Graham Evangelistic Association that equips, mobilizes, and partners with the Hispanic church to have the greatest Gospel impact possible.  Joe is passionate about seeing the local church around the world active in proclaiming and demonstrating the Gospel in their communities.</p><p>Joe is married to his wife Melissa, and they have two children; Caleb and Lily.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/mottesi-alberto.jpg"
                    alt="DR. ALBERTO MOTTESI"
                    className="bio-photo"
                  />
                }
                buttonText="DR. ALBERTO MOTTESI"
                content="<p><strong>DR. ALBERTO MOTTESI</strong> is one of the most well-known and respected lecturers in the Hispanic-American world. His radio and television programs are broadcasted daily by more than 10,000 mass media channels. NRB had awarded him the “Golden Microphone” for his excellence in communications. The press has called him “Pastor of the Presidents” for his continuous ministry to leaders of different levels in Latin American nations. His leadership seminars annually attract thousands of leaders from all backgrounds, and his massive meetings are among some of the largest mass movements in the history of the Hispanic people. In the last 40 years, he has preached in person to more than 30 million people. He has published 17 books. His literary work, <em>America 500 Años Después (America 500 Years Later)</em>, has been adopted as a textbook in the educational system of several countries. He continues to speak at national and international congresses. He has received doctoral degrees from four universities in the United States, Colombia and Mexico.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/palau-kevin.jpg"
                    alt="KEVIN PALAU"
                    className="bio-photo"
                  />
                }
                buttonText="KEVIN PALAU"
                content="<p><strong>KEVIN PALAU</strong> is the son of international evangelist Luis Palau. He joined the Luis Palau Association in 1985 and began directing the day-to-day operation of the ministry in the late 1990s. Under his leadership LPA has produced some of the largest Christian events ever staged, created a worldwide network of hundreds of partner evangelists, and developed new models for citywide outreach that integrate major community service initiatives with open-air evangelistic gatherings. Kevin holds a degree in religious studies from Wheaton College. Today Kevin serves as C.E.O. of the Luis Palau Association, and lives in Beaverton Oregon with his wife Michelle, and their three young adult children.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2023/04/biophotos/porras-sixto.jpg"
                    alt="SIXTO PORRAS"
                    className="bio-photo"
                  />
                }
                buttonText="SIXTO PORRAS"
                content="<p><strong>SIXTO PORRAS</strong> is the Vice President for Hispanic Ministry Development for Enfoque en la Familia, Focus on the Family’s Spanish speaking ministry. He has produced more than 12,000 radio and television programs, which have been broadcasted in more than 684 stations and 38 countries. As a gifted speaker is frequently interviewed by multiple media sources on family issues and has been a speaker before the Diplomatic missions in Latin America at the UN headquarters in New York. He has spoken to thousands in topics relating to the family, youth, communication between parents and children, and personal development in numerous countries around Latin America and the World. Sixto is also an author and an award winner. He currently lives in Colorado with his wife Helen, and has two sons and three grandchildren.</p>"
              />
              <ModalDialog
                buttonImage={
                  <img
                    src="https://s3.theark.cloud/static-billygraham-org/sites/summitdeevangelismo/uploads/prod/2024/05/biophotos/SaraiRivera.png"
                    alt="SARAI RIVERA"
                    className="bio-photo"
                  />
                }
                buttonText="SARAI RIVERA"
                content="<p><strong>SARAI RIVERA</strong>, a talented songwriter and singer, traces her spiritual and musical roots to a deep connection with faith from an early age. Raised in a pastoral home, Sarai was immersed in the atmosphere of her local church, where worship is experienced and practiced as a natural part of daily life. However, it wasn’t until her teenage years that she discovered her true gift for music, a talent she attributes to her father’s fervent prayers, who pleaded with God for her birth.</p><p>With a powerful voice and an unbreakable passion, Sarai has established herself as a worshiper who openly expresses her love and devotion towards God, who granted her life and the calling to glorify Him through her art. Since the age of 12, she began exploring songwriting, a journey that intensified at 13, in the privacy of her bedroom, in front of a piano. It was those moments of personal worship where Sarai experienced a transformative encounter with Jesus Christ, marking the beginning of a relationship that continues to inspire her music and life.</p><p>Through her songs, Sarai Rivera seeks not only to share her faith but also to convey the message of salvation, hope, and love, inviting others to experience the same transformation and peace she has known in Christ.</p>"
              />
            </section>
            <hr />
            <h3>
              <span class="underline">Topics</span>
            </h3>{' '}
            <p>
              The Evangelism Summit has been designed to remind the church of
              the Good News of Jesus Christ. In light of that truth, we will
              explore our call to:
            </p>
            <section class="topics">
              <Accordion
                title="WE PROCLAIM THE CROSS FOR THE SAKE OF HIS NAME"
                content="<p>All those involved in the task of evangelism know that the word <em>Gospel</em>, means “good news!” We sing of the cross; we rejoice in the cross; we meditate upon the cross. Whenever we share in the communion service, we remember the cross in obedience to Jesus’ command. It has been the object of so many paintings throughout history, and countless people wear it as a symbol of their faith. It certainly is good news. But when it comes to sharing with those who are not yet believers, the cross is often the hardest part of the Gospel to proclaim.</p>
                <p>As the church, our glorious task is not just to speak about the loveliness and humility of Jesus—wonderful though those characteristics are—or the helpfulness and goodness of the church, which Jesus said should be apparent to all. <em>“We preach Christ crucified”</em> (1 Corinthians 1:23) for without it, there is no Gospel and there is no salvation. The cross is the heart of the Gospel.</p>"
              />
              <Accordion
                title="WE LIVE HOLY LIVES FOR THE SAKE OF HIS NAME"
                content="<p>It is sad that our culture views church leadership with suspicion, amusement, pity, or as people who are weak. In reality, we know nothing could be further from the truth. We are called to live a holy life. Peter entreats us, <em>“But as he who called you is holy, you also be holy in all your conduct, since it is written, ‘You shall be holy, for I am holy’ ”</em> (1 Peter 1:15–16).</p>
                <p>Sadly, today, we too often hear of Christian leaders who fail, drawing people away from Christ and nullifying their Gospel witness. We must be those who follow Paul’s teaching that <em>“whatever you do, in word or deed, do everything in the name of the Lord Jesus”</em> (Colossians 3:17).</p>"
              />
              <Accordion
                title="WE BEAR THE COST OF THE GOSPEL FOR THE SAKE OF HIS NAME"
                content="<p>Everyone knows that sheep and wolves don’t mix! Yet in Matthew 10:16, this is the metaphor Jesus used to describe the relationship between those sent to preach the Gospel of the kingdom of God and the world to which they went. He taught His disciples that taking the Gospel to the world will often be misunderstood, costly, painful, divisive, lonely, and—in some cases—even illegal and life threatening!</p>
                <p>The prophets of the Old Testament frequently suffered these consequences, and certainly the New Testament disciples experienced this as well. Tradition indicates that of Jesus’ 12 disciples, only John died of natural causes; the rest were martyred. There is a cost to sharing the Gospel today. <em>“All men will hate you because of me,”</em> Jesus said. (See Matthew 10:22.)</p>
                <p>The cost is not always death, but it does require endurance. As bearers of the Good News, frequently the question is not, “Are you willing to die for the sake of the Name?” but, “Are you willing to live for the sake of the Name?” Are you willing to live with endurance, bearing the pain of obedience because we are sent into a hostile world?</p>"
              />
              <Accordion
                title="WE UNITE WITH THE WHOLE CHURCH FOR THE SAKE OF HIS NAME"
                content="<p>If you ask any person what they think of the church of Jesus Christ, there is likely to be one of two answers. Some will say, “The church is irrelevant, and it has almost no bearing on my life.” Others will comment, “Which church are you talking about? There are so many, and you spend your time arguing with each other.” It is clear that internal differences, however well intended, can easily become a barrier to the evangelization of the world. But can Christians unite, especially when our differences are often for good and legitimate reasons?</p>
                <p>What is it that unites us? The work of Jesus Christ and the preaching of it to the world is foundational in bringing believers together. His work, not ours, unites us. So, the call of Peter is to <em>“love one another earnestly from a pure heart”</em> (1 Peter 1:22).</p>"
              />
              <Accordion
                title="WE DEFEND THE GOSPEL FOR THE SAKE OF HIS NAME"
                content="<p>The Gospel has always been under attack. So it should come as no surprise to us if we, too, are rejected and the message despised. It was the promise of Jesus when He said, <em>“If the world hates you, know that it has hated me before it hated you. … If they persecuted me, they will also persecute you”</em> (John 15:18, 20).</p>
                <p>This means that attacks upon both the Gospel itself, the church, and on us personally are no cause for becoming downcast and dejected. It is not only our responsibility to preach and live the Gospel as testimony to the world; we must also defend the Gospel. When opposition arises, we must take the opportunities to defend the Gospel.</p>
                <p>The Apostle Paul saw this defense as one of the main reasons for his imprisonment. He wrote to the Philippians, <em>“I am put here for the defense of the gospel”</em> (Philippians 1:16).</p>
                <p>How then can we prepare to play our part? How can we respond to Jude’s admonition to <em>“contend for the faith that was once for all delivered to the saints”</em> (Jude 1:3)?</p>"
              />
              <Accordion
                title="WE MAKE DISCIPLES FOR THE SAKE OF HIS NAME"
                content="<p>From a New Testament perspective, there is no doubt that discipleship is part of evangelism. In fact, the heart of the Great Commission that Jesus gave the apostles (see Matthew 28:19–20) was to go and make disciples of all nations.</p>
                <p>This was not a new concept. Isaiah, for example, entrusted his teaching to a group of disciples (Isaiah 8:16), and the rabbinic schools and practices did this also. Jesus emphasised this throughout His ministry, most notably when He instructed His disciples on reaching others: teach <em>“them to observe all that I have commanded you”</em> (Matthew 28:20).</p>
                <p>This dual emphasis of calling people was, firstly to faith in Jesus and His saving work, but also to a lifetime of daily obedience to His teaching (see Acts 6:7). We must not separate them.</p>"
              />
            </section>{' '}
            <hr />
            <h3>
              <span class="underline">Agenda</span>
            </h3>
            <ul class="schedule">
              <li>
                9 AM - 12 PM <span>Morning</span>
              </li>
              <li>
                12 PM - 1 PM <span>Lunch</span>
              </li>
              <li>
                1 PM - 4 PM <span>Afternoon</span>
              </li>
              <li>
                4 PM <span>End</span>
              </li>
            </ul>
            <hr />
            <h3>
              <span class="underline">Resources</span>
            </h3>
            <p>
              Use the downloadable resources below to promote the Summit de
              Evangelismo with your church leaders.
            </p>
            <Accordion
              title="Resources"
              content="
        <div class='downloadWrapper'>
        <div class='downloadTitle'>
        <div class='downloadIcon'></div>
        <a href='https://vimeo.com/915335869/64ba251705?share=copy' style='text-transform: unset'><h4>Summit de Evangelismo 2024 teaser 3 horizontal</h4></a>
        </div>
        <a href='https://cdnapisec.kaltura.com/p/650742/sp/65074200/playManifest/entryId/1_va2jx7yl/format/download/protocol/https/flavorParamIds/0' target='_blank' class='button'>Download</a>
        </div>
        <div class='downloadWrapper'>
        <div class='downloadTitle'>
        <div class='downloadIcon'></div>
        <a href='https://vimeo.com/917180696/b215b2efbe?share=copy' style='text-transform: unset;'><h4>Summit de evangelismo 2024 teaser 3 vertical</h4></a>
        </div>
        <a href='https://cdnapisec.kaltura.com/p/650742/sp/65074200/playManifest/entryId/1_w3rene1s/format/download/protocol/https/flavorParamIds/0' target='_blank' class='button'>Download</a>
        </div>
        <div class='downloadWrapper'>
        <div class='downloadTitle'>
        <div class='downloadIcon'></div>
        <h4>Promo Video - #3</h4>
        </div>
        <a href='https://cdnapisec.kaltura.com/p/650742/sp/65074200/playManifest/entryId/1_9jw7tzks/format/download/protocol/https/flavorParamIds/0' target='_blank' class='button'>Download</a>
        </div>
        <div class='downloadWrapper'>
        <div class='downloadTitle'>
        <div class='downloadIcon'></div>
        <h4>Social Media Graphics</h4>
        </div>
        <a href='/resources/promo-2024.zip' target='_blank' class='button'>Download</a>
        </div>
        <div class='downloadWrapper'>
        <div class='downloadTitle'>
        <div class='downloadIcon'></div>
        <h4>Event Poster</h4>
        </div>
        <a href='https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/04/20171-Hispanic-Initiatives-Evangelism-Summit-2024-PROMO-Poster-PRESS-woBleed.pdf' target='_blank' class='button'>Download</a>
        </div>
        "
            />
            <hr />
            <h3>
              <span class="underline">FAQ</span>
            </h3>
            <Accordion
              title="Who can attend?"
              content="Pastors and church leaders."
            />
            <Accordion
              title="How much does this event cost?"
              content="The event is free."
            />
            <Accordion
              title="What time does it start?"
              content="The event begins at 9 a.m. and ends at 4 p.m."
            />
            <Accordion
              title="Should I bring or buy my lunch?"
              content="We will provide lunch for all attendees."
            />
            <Accordion
              title="Will the event be broadcast on social media?"
              content="No, the event is in person only.<br/><br/>If you have any further questions please send an email to hispanic@bgea.org."
            />
            <hr />
            <h3>
              <span class="underline">Past Events</span>
            </h3>
            <div className="columns locations">
              <div className="column is-4">
                <div class="card text-center">
                  <a
                    href="https://billygraham.org/story/bgeas-summit-de-evangelismo-kicks-off-in-los-angeles/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="card-content">
                      <div class="content">
                        <StaticImage
                          src="../images/california-state-blue.svg"
                          className="locations-img"
                        />
                        <h5>Los Angeles, CA</h5>
                        <p>August 6, 2022</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="column is-4">
                <div class="card">
                  <a
                    href="https://es.billygraham.org/galeria/la-comunidad-hispana-de-chicago-le-dio-bienvenida-a-summit-de-evangelismo/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="card-content">
                      <div class="content">
                        <StaticImage
                          src="../images/illinois-state-blue.svg"
                          className="locations-img"
                        />
                        <h5>Chicago, IL</h5>
                        <p>September 10, 2022</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="column is-4">
                <div class="card">
                  <a
                    href="https://billygraham.org/story/the-presence-of-god-is-here-evangelism-summit-calls-the-hispanic-church-to-unity/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="card-content">
                      <div class="content">
                        <StaticImage
                          src="../images/texas-state-blue.svg"
                          className="locations-img"
                        />
                        <h5>Coppell, TX</h5>
                        <p>October 15, 2022</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="column is-4">
                <div class="card text-center">
                  <a
                    href="https://billygraham.org/story/pastors-in-the-rio-grande-valley-encouraged-to-return-to-the-heart-of-the-gospel/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="card-content">
                      <div class="content">
                        <StaticImage
                          src="../images/texas-state-blue.svg"
                          className="locations-img"
                        />
                        <h5>McAllen, TX</h5>
                        <p>September 30, 2023</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="column is-4">
                <div class="card text-center">
                  <a
                    href="https://es.billygraham.org/galeria/un-llamado-a-la-unidad-en-phoenix/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="card-content">
                      <div class="content">
                        <StaticImage
                          src="../images/arizona-state-blue.svg"
                          className="locations-img"
                        />
                        <h5>Phoenix, AZ</h5>
                        <p>October 7, 2023</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="column is-4">
                <div class="card text-center">
                  <a
                    href="https://billygraham.org/story/called-to-serve-in-ministry-after-retirement/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="card-content">
                      <div class="content">
                        <StaticImage
                          src="../images/texas-state-blue.svg"
                          className="locations-img"
                        />
                        <h5>El Paso, TX</h5>
                        <p>October 14, 2023</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="column is-4">
                <div class="card text-center">
                  <a
                    href="https://es.billygraham.org/galeria/nuevas-fuerzas-para-pastores-en-san-diego/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div class="card-content">
                      <div class="content">
                        <StaticImage
                          src="../images/california-state-blue.svg"
                          className="locations-img"
                        />
                        <h5>San Diego, CA</h5>
                        <p>October 28, 2023</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="column is-4">
                <div class="card text-center">
                  <div class="card-content">
                    <a
                      class="content"
                      href="https://es.billygraham.org/galeria/pastores-y-lideres-hispanos-encuentran-renovacion-espiritual-en-denver/"
                      target="_blank"
                    >
                      <StaticImage
                        src="../images/colorado-state-blue.svg"
                        className="locations-img"
                      />
                      <h5>Denver, CO</h5>
                      <p>August 3, 2024</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="getInvolvedVideo" className="videoContainer is-hidden">
            <section className="video">
              <div className="flexVideo">
                <Video id="1_dkftibue" />
              </div>
            </section>
          </div>
        </div>
      </Tabs>
    </section>
  </Layout>
);

export default IndexPage;
